import { FC } from "react"

type Props = {
  fill?: string,
  width?: number,
  height?: number,
  color ?: string,
}

const ArrowGreen : FC<Props> = ({fill,color, width , height, ...props}) => (

    <svg width="15" height="29" viewBox="0 0 15 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.09375 1.6875L13.9062 14.5L1.09375 27.3125" stroke="#0096A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default ArrowGreen

